/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Testimonials = () => {
    const sectionTitle = 'What people are saying about us?';
    const sectionTitleText =
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut efficitur ante. Donec dapibus dictum scelerisque.';
  
  return (
    <section classNameName="pj vp mr">
      {/* Section Title Start */}
      <div className="container my-5">
    <h1 className="text-center mb-4 fk vj pr kk wm on/5 gq/2 bb _b"  style={{fontSize: '40px', color: '#000'}}>What Our Customers Say</h1>
    <div className="row">

      <div className="col-md-6 col-lg-3 mb-4">
        <div className="testimonial-card p-3">
          <h2 style={{fontSize: '20px', marginBottom: 10}}>Herbert Sylvester</h2>
          <p>E-tracka is the easiest way to gather information from your tenants. I have experienced seamless onboarding of my tenants.</p>
        </div>
      </div>
      <div className="col-md-6 col-lg-3 mb-4">
        <div className="testimonial-card p-3">
          <h2 style={{fontSize: '20px', marginBottom: 10}}>Olayiwola Adeola</h2>
          <p>I list and rent my properties easily on E-tracka. It makes communication with my tenants very effective. My tenants are wowed with the way I send reminders and issue payment receipts to their emails.</p>
        </div>
      </div>

      <div className="col-md-6 col-lg-3 mb-4">
        <div className="testimonial-card p-3">
          <h2 style={{fontSize: '20px', marginBottom: 10}}>Ikechukwu Julianna</h2>
          <p>E-tracka has improved the communication between my tenants and I. I am able to verify my tenants and be sure they are credible and worthy. I also send them receipts from the platform to avoid any conflict.</p>
        </div>
      </div>

      <div className="col-md-6 col-lg-3 mb-4">
        <div className="testimonial-card p-3">
          <h2 style={{fontSize: '20px', marginBottom: 10}}>Haruna Ahmed</h2>
          <p>E-tracka is a wonderful innovation in Nigeria. I am always happy to watch my credit score grow through my rental payments.</p>
        </div>
      </div>
    </div>
  </div>
  <hr></hr>
    </section>
  );
};

export default Testimonials;
