// src/components/Home.js
import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import ContactUs from './elements/Contact/ContactUs';

const Contact = () => {
  return (
    <div>
      <Header page="contact"/>
      <ContactUs/>
      <Footer/>
    </div>
  );
};

export default Contact;
