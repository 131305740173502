import React from "react";

const FaqSection = () => {
  return (
    <section id="support" className="i pg fh rm ji gp uq">
      {/* Bg Shapes */}
      <img src="images/shape-06.svg" alt="Shape" className="h aa y" />
      <img src="images/shape-12.svg" alt="Shape" className="h ca u" />
      <img src="images/shape-12.svg" alt="Shape" className="h w da ee" />
      <img src="images/shape-12.svg" alt="Shape" className="h p s" />
      <img src="images/shape-13.svg" alt="Shape" className="h r q" />

      {/* Section Title Start */}
      <div>
        <div className="animate_top bb ze rj ki xn vq">
          <h2
            x-text="sectionTitle"
            className="fk vj pr kk wm on/5 gq/2 bb _b"
          ></h2>
          <p className="bb on/5 wo/5 hq" x-text="sectionTitleText"></p>
        </div>
      </div>
      {/* Section Title End */}

      <div className="i va bb ye ki xn wq jb mo">
        <h1 className="fk vj pr kk wm on/5 gq/2 bb _b">Frequently Asked Questions</h1>
        <div class="container my-5">
        {/* <h2 class="text-center mb-4">Frequently Asked Questions</h2> */}
        
        <div class="accordion" id="faqAccordion">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Q: What is E-tracka?
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                    <div class="accordion-body" >
                        <p style={{color: 'black'}}>
                        A: E-tracka is a smart app for the landlords and property managers to track, verify and screen
                        tenants. The tenants also use the app to build their credit score.
                        </p>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Q: How can I trust the data provided by E-Tracka?
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                    <div class="accordion-body">
                        A:  E-Tracka ensures all data is verified and accurate, providing reliable information for
                        making informed decisions.
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Q: How do I get started with E-Tracka?
                    </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                    <div class="accordion-body">
                        A: Sign up on our website and explore our services tailored to your real estate needs.
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Q: Can I contact property owners directly through E-Tracka?
                    </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
                    <div class="accordion-body">
                        A: Yes, E-Tracka allows tenants to chat directly with landlords and property managers.
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
    </section>

    
  );
};

export default FaqSection;
