/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const CTA = () => {
  return (
    <section className="i pg gh ji" style={{ backgroundColor: "#000000" }}>
      <img className="h p q" src="images/shape-08.svg" alt="Bg Shape" />

      <div className="bb ze ki xn 2xl:ud-px-0">
        <div className="tc uf sn tn un gg">
          <div className="animate_left to/2">
            <h2 className="fk vj zp pr lk ac">Are you an estate agent or developer? </h2>
            <p className="lk">
            List your property for FREE.
            </p>
          </div>
          <div className="animate_right bf">
            <a  href="https://etracker-software-frontend.vercel.app" className="vc ek kk hh  ol il cm gi hi">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </section>
    
  );
};

export default CTA;
