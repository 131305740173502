/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Hero = () => {
  return (
    <><section className="gj do ir " style={{ paddingBottom: 35, backgroundColor: "#000", color: "white" }}>
      {/* Hero Images */}
      <div className="xc fn zd/2 2xl:ud-w-187.5 bd 2xl:ud-h-171.5 h q r">
        {/* <img
      src="images/shape-18.svg"
      alt="shape"
      className="xc 2xl:ud-block h t -ud-left-[10%] ua"
    /> */}
        {/* <img
      src="images/shape-06.svg"
      alt="shape"
      className="xc 2xl:ud-block h u p va"
    /> */}
        {/* <img
      src="images/shape-03.svg"
      alt="shape"
      className="xc 2xl:ud-block h v w va"
    /> */}
        {/* <img src="images/shape-02.svg" alt="shape" className="h q r" /> */}
        <img src="images/chair.png" style={{width: 500, marginTop: 150}} alt="Woman" className=" " />
      </div>

      {/* Hero Content */}
      <div className="bb ze ki xn 2xl:ud-px-0">
        <div className="tc _o">
          <div className="animate_left jn/2">

            {/* badges */}
            <span class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Trusted Real Estate Partner</span>
            {/* <span class="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Secured</span> */}
            {/* <span class="bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300">Reliable</span> */}
            <span class="bg-pink-100 text-pink-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">Cost Effective</span>


            <h1 className="fk vj zp or kk wm wb" style={{ color: "white" }}>
            Smart App for the landlords to verify, track and screen tenants
            </h1>
            <p className="fq">
            E-tracka is a smart app built for landlords, real estate agents and property managers that
            helps to verify, screen and conduct credit checks for tenants and to manage real estate
            properties for effective and efficient decisions. The tenants also use the app to build their
            credit history as we track the monthly rentals and other bill payments.            </p>

            <div className="tc tf yo zf mb">
              <a
                style={{ backgroundColor: "#2e43e7" }}
                href="https://etracker-software-frontend.vercel.app"
                className="ek jk lk gh gi hi  ml il vc _d _l"
              >
                Get Started
              </a>

              {/* <span className="tc sf">
      <a href="/contact" className="inline-block ek xj kk wm">
        {" "}
        Contact Us{" "}
      </a>
      <span className="inline-block">
        For any question or concern
      </span>
    </span> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="">
        <img src="images/bg1.jpg" alt="Woman" className="" />
      </section>
      </>
  );
};

export default Hero;
