// src/components/Home.js
import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import FaqSection from './elements/FaqSection/Faq';

const Faq = () => {
  return (
    <div>
      <Header page="Faq"/>
      <FaqSection/>
      <Footer/>
    </div>
  );
};

export default Faq;
