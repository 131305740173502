/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";

const Header = ({ page }) => {
  const [stickyMenu, setStickyMenu] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setStickyMenu(window.pageYOffset > 20);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
     style={{backgroundColor: "#000000"}}
      className={`g s r vd ya cj ${stickyMenu ? "hh sm _k dj bl ll" : ""}`}
    >
      <div className="bb ze ki xn 2xl:ud-px-0 oo wf yf i">
        <div className="vd to/4 tc wf yf">
          <a href="/">
            <img
              className="om"
              src="images/etracker-logo.svg"
              width={150}
              alt="Etracker Logo"
            />
          </a>
          <button
            className="po rc"
            onClick={() => setNavigationOpen(!navigationOpen)}
          >
            <span className="rc i pf re pd">
              <span className="du-block h q vd yc"style={{backgroundColor: 'white'}} >
                <span
                // style={{color: 'black'}}
                  className={`rc i r s eh um tg te rd eb ml jl dl ${
                    !navigationOpen ? "ue el" : ""
                  }`}
                ></span>
                <span
                  className={`rc i r s eh um tg te rd eb ml jl fl ${
                    !navigationOpen ? "ue qr" : ""
                  }`}
                ></span>
                <span
                  className={`rc i r s eh um tg te rd eb ml jl gl ${
                    !navigationOpen ? "ue hl" : ""
                  }`}
                ></span>
              </span>
              <span className="du-block h q vd yc lf" style={{color: 'white'}}>
                <span
                  className={`rc eh um tg ml jl el h na r ve yc ${
                    !navigationOpen ? "sd dl" : ""
                  }`}
                ></span>
                <span
                  className={`rc eh um tg ml jl qr h s pa vd rd ${
                    !navigationOpen ? "sd rr" : ""
                  }`}
                ></span>
              </span>
            </span>
          </button>
        </div>

        <div
        style={{ padding: 20}}
          className={`vd wo/4 sd qo f ho oo wf yf ${
            navigationOpen ? "d hh rm sr td ud qg ug jc yh" : ""
          }`}
        >
          <nav >
            <ul className="tc _o sf yo cg ep" >
              {/* <li>
                <a
                  href="/"
                  className="xl"
                  // style={{ color: page === "home" ? "#2e43e7" : "white" }}
                >
                  Home
                </a>
              </li> */}
              <li>
                <a
                  href="#services"
                  className="xl"
                  // style={{ color: page === "services" ? "#2e43e7" : "black" }}
                >
                  Services
                </a>
              </li> 
               <li>
                <a
                  href="/contact"
                  className="xl"
                  // style={{ color: page === "contact" ? "#2e43e7" : "white" }}
                >
                  Contact Us
                </a>
              </li> 
              {/* <li>
                <a
                  href="/about"
                  className="xl"
                  // style={{ color: page === "about" ? "#2e43e7" : "white" }}
                >
                  About Us
                </a>
              </li> */}
              <li className="dropdown">
              <a
                href="/about"
                className="xl dropdown-toggle"
                id="dropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {/* <li>
                  <a className="dropdown-item" href="/about">
                    About Us
                  </a>
                </li> */}
                <li>
                  <a className="dropdown-item" href="/about">
                    Tenant
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/about">
                    Landlord
                  </a>
                </li>
              </ul>
            </li>
            </ul>
          </nav>

          <div className="hidden lg:block tc wf ig pb no">
            <div className="tc wf ig pb no">
              <div
                className={`pc h io pa ra ${
                  navigationOpen ? "!-ud-visible" : "d"
                } hidden-on-mobile`}
              >
                <a
                  style={{ backgroundColor: "#ffffff", color: "#2e43e7" }}
                   href="https://etracker-software-frontend.vercel.app/auth/signin"
                  className="ek jk lk gh gi hi ml il vc _d _l"
                >
                 Login
                </a>

                <a
                  style={{ backgroundColor: "#2e43e7" }}
                   href="https://etracker-software-frontend.vercel.app/auth/signup"
                  className="ek jk lk gh gi hi ml il vc _d _l"
                >
                  Create Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
