import React, { useState } from 'react';
import './QuickLook.css';  // Assuming external CSS for styling

const QuickLook = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    { src: './images/slides/dashboard-overview.png', alt: 'Dashboard Overview', caption: 'Dashboard Overview' },
    { src: './images/slides/properties.png', alt: 'Properties Management', caption: 'Properties Management' },
    { src: './images/slides/tenant-info.png', alt: 'Tenant Information', caption: 'Tenant Information' },
    { src: './images/slides/tenant-info.png', alt: 'Tenant Information', caption: 'Tenant Information' },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <section id="quick-look" className="quick-look-section">
      <h2 className="quick-look-title">Quick Look</h2>
      <div className="carousel-container container">
        <div
          className="carousel-slides"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div className="carousel-slide" key={index}>
              <img src={slide.src} alt={slide.alt} className="carousel-image" />
              {/* <p className="carousel-caption">{slide.caption}</p> */}
            </div>
          ))}
        </div>

        <button className="carousel-prev" onClick={handlePrev}>
          &lt;
        </button>
        <button className="carousel-next" onClick={handleNext}>
          &gt;
        </button>
      </div>
    </section>
  );
};

export default QuickLook;
