// src/components/Home.js
import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import NotFoundPage from './elements/NotFound/NotFound';


const NotFound = () => {
  return (
    <div>
      <Header page="404"/>
      <NotFoundPage/>
      <Footer/>
    </div>
  );
};

export default NotFound;
